<template>
	<div>
		<div class="selections">
			Auspicious Muhurtha at <b>{{selectedCity}}</b>
			<br/> 
			<select  v-model="selectedCity" @change="cityChanged">
				<option v-for="place in places" :key="place.id">{{place.name}}</option>
			</select>
			<br/> 
			<button @click="previousDate">Prev</button>
			{{panchangDateString}}
			<button @click="nextDate">Next</button>
			<br/> for <br/>
			Moon sign <select  v-model="selectedSignNo">
				<option value="-1">Please Select</option>
				<option v-for="zodiacSign in zodiacSigns" :key="zodiacSign.id" :value="zodiacSign.id">{{zodiacSign.name}}</option>
			</select> Nakshatra <select  v-model="selectedNakshatraNo" @change="nakshatraChanged">
				<option value="-1">Please Select</option>
				<option v-for="nakshatra in nakshatras" :key="nakshatra.id" :value="nakshatra.id">{{nakshatra.name}}</option>
			</select>
        </div>
		
		<muhurtha-graph :key="componentKey" :panchangDate="panchangDate" :city="selectedCity" :moonSignNo="selectedSignNo" :nakshatraNo="selectedNakshatraNo"></muhurtha-graph>
	</div>
</template>

<script>
	import moment from 'moment-timezone'
	moment.tz.setDefault('UTC');
	import MuhurthaGraph from './MuhurthaGraph.vue';

    export default {
        data() {
            return {
				selectedCity: 'Bhubaneshwar',
				panchangDate: moment().local(),
				panchangDateString: '',
				componentKey: 0,
				selectedSignNo: '-1', 
				zodiacSigns: null,
				selectedNakshatraNo: '-1',
            };
        },
        methods: {
			nextDate(){
				this.resetMenuSelections();
				this.panchangDate = this.panchangDate.add(1, 'days');
				this.panchangDateString = this.panchangDate.format('DD-MM-YYYY'); 
				return this.componentKey=!this.componentKey;
			},
			previousDate(){
				this.resetMenuSelections();
				this.panchangDate = this.panchangDate.subtract(1, 'days');
				this.panchangDateString = this.panchangDate.format('DD-MM-YYYY'); 
				return this.componentKey=!this.componentKey;
			},
			cityChanged(){
				this.resetMenuSelections();
				this.panchangDateString = this.panchangDate.format('DD-MM-YYYY'); 
				return this.componentKey=!this.componentKey;
			},
			nakshatraChanged(){
				this.resetMenuSelections();
				this.panchangDateString = this.panchangDate.format('DD-MM-YYYY'); 
				return this.componentKey=!this.componentKey;
			},
			resetMenuSelections(){
				this.$store.commit('vmenuActive', false);
				const prevSelectedHmenuItem = this.$store.state.selectedHmenuItem;
				if (prevSelectedHmenuItem !== null)
					prevSelectedHmenuItem.selected = false;
			}
		},
		watch: {
			selected: function (value) {
				return this.componentKey=!this.componentKey;
			}
		},
		computed:{
			places(){
				return this.$store.state.cities;
			},
			nakshatras(){
				const signs = this.$store.state.zodiacSigns;
				let nakshatras = ['Please Select'];
				for (let i = 0; i < signs.length; i++){
					const sign = signs[i];
					if (sign.id === this.selectedSignNo){
						nakshatras = sign.nakshatras;
					}
				}
				return nakshatras;
			}
		},
		components:{
			MuhurthaGraph
		},
		mounted(){
			this.panchangDateString = this.panchangDate.format('DD-MM-YYYY'); 
			this.zodiacSigns = this.$store.state.zodiacSigns;
		}
	}

</script>
<style scoped>
	.selections{
		color: white;
	}
</style>